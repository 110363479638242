<template>
  <div>
    <div>
      <b-row>
        <b-col class="d-flex">
          <div>
            <div style="margin-bottom: 10px">PAYMENT REQUEST:</div>
            <b-button-group class="so-status order-neg1">
              <b-form-radio-group buttons button-variant="secondary">
                <b-form-radio-group
                  id="e-pay-radio"
                  v-model="selected"
                  :options="radioOptions"
                  button-variant="secondary"
                  size="md"
                  buttons
                ></b-form-radio-group>
              </b-form-radio-group>
            </b-button-group>
          </div>
          <div v-if="hasRefundableRequests || hasVoidableRequest" class="ml-5">
            <div class="ml-3" style="margin-bottom: 10px">PAYMENT RETURNS:</div>
            <b-button-group v-if="hasEPayRequests" class="order-neg1 void-button">
              <b-form-radio-group buttons>
                <b-form-radio-group
                  id="e-pay-radio"
                  v-model="selected"
                  :options="refundRadioOptions"
                  button-variant="danger"
                  size="md"
                  buttons
                ></b-form-radio-group>
              </b-form-radio-group>
            </b-button-group>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="selected == 1 || selected == 2" style="margin-top: 25px">
        <b-col cols="3" style="margin-top: 5px">
          <b-input-group prepend="$">
            <b-form-input
              ref="input"
              v-model="requestedAmount"
              v-currency="currencyOptions"
              :state="requestedAmount < 0 ? false : null"
              placeholder="Amount"
            />
            <div v-if="requestedAmount < 0" class="error">Must be greater than 0</div>
          </b-input-group>
        </b-col>
        <b-col v-show="selected == 1" cols="5" style="margin-top: 5px; margin-left: -20px">
          <b-form-input
            ref="input"
            v-model="$v.ePayEmail.$model"
            placeholder="Email Address"
            :state="!$v.ePayEmail.$error ? null : false"
          />
          <div v-show="$v.ePayEmail.$error && !$v.ePayEmail.email" class="error">Enter a valid Email Address.</div>
        </b-col>
        <b-col v-show="selected == 2" cols="5" style="margin-top: 10px; margin-left: -20px">
          <div>Send transaction to Card Reader</div>
        </b-col>
        <b-col cols="2" style="float: right; margin-left: -20px; margin-top: 5px">
          <b-button
            :disabled="
              (selected == 1 && (ePayEmail == null || ePayEmail.trim() == '' || $v.ePayEmail.$error)) ||
              order.total == 0 ||
              requestedAmount == null ||
              requestedAmount.trim() == '' ||
              requestedAmount <= 0 ||
              sending
            "
            class="btn-primary"
            @click="send"
          >
            <div v-show="sending">
              <b-spinner small></b-spinner>
              Sending...
            </div>
            <div v-show="!sending">Send</div>
          </b-button>
        </b-col>
      </b-row>
      <b-row v-if="hasEPayRequests && (selected == 3 || selected == 4)" style="margin-top: 25px" class="d-inline-block">
        <div v-if="selected == 3">
          Select transaction you would like to VOID and click send. Only eligible transactions are available
        </div>
        <div v-if="selected == 4">
          Select transaction you would like to refund, enter refund amount not to exceed the transaction total, and
          click Send to complete the Refund
        </div>
        <div class="d-flex mt-2">
          <v-select
            v-if="selected == 3"
            id="voidTransaction"
            v-model="voidRequest"
            :options="voidableRequests"
            select-on-tab
            :clearable="false"
            label="voidTransaction"
            placeholder="Select Transaction"
          >
            <template #option="request">
              <span>Amount: {{ request.requestedAmount | currency }} -- Approval Code: {{ request.approvalCode }}</span>
            </template>
            <template #selected-option="request">
              <span>Amount: {{ request.requestedAmount | currency }} -- Approval Code: {{ request.approvalCode }}</span>
            </template>
          </v-select>
          <v-select
            v-if="selected == 4"
            id="refundTransaction"
            v-model="refundRequest"
            :options="refundableRequests"
            select-on-tab
            :clearable="false"
            label="refundTransaction"
            placeholder="Select Transaction"
          >
            <template #option="request">
              <span>Amount: {{ request.requestedAmount | currency }} -- Approval Code: {{ request.approvalCode }}</span>
            </template>
            <template #selected-option="request">
              <span>Amount: {{ request.requestedAmount | currency }} -- Approval Code: {{ request.approvalCode }}</span>
            </template>
          </v-select>
          <v-select
            v-if="selected == 4"
            id="amountRefund"
            class="ml-3"
            :options="refundOrders"
            select-on-tab
            :clearable="false"
            label="amountRefund"
            placeholder="Amount"
          >
            <template #option="ord">
              <span class="reversal">Amount: {{ ord.amount | currency }} -- Reversal: {{ ord.orderId }}</span>
            </template>
            <template #selected-option="ord">
              <span class="reversal">Amount: {{ ord.amount | currency }} -- Reversal: {{ ord.orderId }}</span>
            </template>
          </v-select>
          <b-button
            :disabled="
              (selected == 4 && refundRequest == null) || (selected == 3 && voidRequest == null) || sendingReturn
            "
            class="btn-primary"
            @click="sendReturn"
          >
            <div v-show="sendingReturn">
              <b-spinner small></b-spinner>
              Sending...
            </div>
            <div v-show="!sendingReturn">Send</div>
          </b-button>
        </div>
      </b-row>
      <b-row v-if="order.ePayRequests" class="mt-3">
        <h4 class="mb-0">Payments</h4>
        <b-table striped class="border" no-border-collapse :items="order.ePayRequests" :fields="requestFields">
          <template #cell(ePayRequestStatus)="{ item }">
            <b-badge :variant="statusBadgeVariant(item.ePayRequestStatus)" class="mr-1" style="min-width: 50px">
              {{ statusText(item.ePayRequestStatus) }}
            </b-badge>
          </template>
          <template #cell(dateCreate)="{ item }">
            {{ item.dateCreate | dateTime }}
          </template>
          <template #cell(empIdCreate)="{ item }">
            {{ getEmployee(item.empIdCreate) }}
          </template>
          <template #cell(ePayRequestType)="{ item }">
            {{ item.ePayRequestType == 2 ? item.ePayEmail : 'In Person' }}
          </template>
          <template #cell(requestedAmount)="{ item }">
            {{ item.requestedAmount | currency }}
          </template>
        </b-table>
        <!--  <b-col>
           <div v-for="(item, index) in ePayRequests.slice().reverse()" :key="index">
            <div v-if="item.ePayRequestType == 1">
              <b-badge v-if="item.ePayRequestStatus == 1" variant="primary" class="mr-1">Sent</b-badge>
              <b-badge v-if="item.ePayRequestStatus == 2" variant="success" class="mr-1">Paid</b-badge>
              <b-badge v-if="item.ePayRequestStatus == 3" variant="danger" class="mr-1">Declined</b-badge>
              In person {{ item.dateCreate | dateTime }} to
              <strong>Card Terminal</strong>
              : {{ getEmployee(item.empIdCreate) }} Amount: {{ item.requestedAmount | currency }}
            </div>
            <div v-if="item.ePayRequestType == 2">
              <b-badge v-if="item.ePayRequestStatus == 1" variant="primary" class="mr-1">Sent</b-badge>
              <b-badge v-if="item.ePayRequestStatus == 2" variant="success" class="mr-1">Paid</b-badge>
              <b-badge v-if="item.ePayRequestStatus == 3" variant="danger" class="mr-1">Declined</b-badge>
              Email {{ item.dateCreate | dateTime }} to
              <strong>{{ item.ePayEmail }}</strong>
              : {{ getEmployee(item.empIdCreate) }} Amount: {{ item.requestedAmount | currency }}
            </div>
          </div>
        </b-col> -->
      </b-row>
    </div>
    <unsaved-changes-modal
      ref="UnsavedChangesModal"
      title="Save Changes"
      message="There are unsaved changes. You must save before sending payment notification."
      continue-btn-text="Save changes"
      cancel-btn-text="Cancel"
    ></unsaved-changes-modal>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import { mapGetters, mapActions } from 'vuex';
import { ServiceOrderGetters, ServiceOrderActions } from '@/shared/store/service-order/types';
import { PartsOrderActions } from '@/shared/store/parts-order/types';
import UnsavedChangesModal from '@/shared/components/UnsavedChangesModal';
import { email } from 'vuelidate/lib/validators';
import ErrorService from '@/shared/services/ErrorService';
import SuccessService from '@/shared/services/SuccessService';
import { LookupGetters, LookupActions } from '@/shared/store/lookup/types';

export default {
  name: 'EPayComponent',
  components: {
    'v-select': vSelect,
    'unsaved-changes-modal': UnsavedChangesModal
  },
  props: {
    orderId: {
      type: String,
      default: ''
    },
    order: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      ePayEmail: null,
      path: this.$route.path,
      sending: false,
      sendingReturn: false,
      selected: 1,
      requestedAmount: null,
      currencyOptions: {
        currency: null,
        locale: 'en-US'
      },
      voidRequest: null,
      refundRequest: null
    };
  },
  validations: {
    ePayEmail: {
      email
    }
  },
  computed: {
    ...mapGetters({
      //allowEPay: ServiceOrderGetters.GET_ALLOW_E_PAY,
      // allowEPayInPerson: ServiceOrderGetters.GET_ALLOW_E_PAY_IN_PERSON,
      //serviceOrder: ServiceOrderGetters.GET_SERVICE_ORDER,
      //serviceOrderId: ServiceOrderGetters.GET_SERVICE_ORDER_ID,
      //partOrder: PartOrderGetters.GET_PART_ORDER,
      //partOrderId: PartOrderGetters.GET_PART_ORDER_ID,
      employeeList: LookupGetters.GET_EMPLOYEE_LIST,
      ePayStatusList: LookupGetters.GET_EPAY_STATUSES_LIST,
      ePayTypeList: LookupGetters.GET_EPAY_TYPES_LIST,
      branchId: ServiceOrderGetters.GET_BRANCH_ID
    }),
    ...mapGetters([ServiceOrderGetters.HAS_CHANGES]),
    requestFields() {
      const fields = [
        { key: 'ePayRequestStatus', label: 'Status' },
        { key: 'dateCreate', label: 'Date' },
        { key: 'empIdCreate', label: 'Emp' },
        { key: 'ePayRequestType', label: 'Type' },
        { key: 'requestedAmount', label: 'Amount' },
        { key: 'approvalCode', label: 'Approval Code' }
      ];

      return fields;
    },
    unitId() {
      return this.$router.currentRoute.params.unitId;
    },
    hasChanges() {
      if (!this.isPartOrder) return this[ServiceOrderGetters.HAS_CHANGES];
      else return null;
    },
    hasEPayRequests() {
      return this.order?.ePayRequests?.length > 0;
    },
    hasVoidableRequest() {
      return this.order.ePayRequests?.find(req => req.isVoidable == true);
    },
    voidableRequests() {
      return this.order.ePayRequests?.filter(req => req.isVoidable == true);
    },
    hasRefundableRequests() {
      return this.order.ePayRequests?.find(req => req.isRefundable == true);
    },
    refundableRequests() {
      return this.order.ePayRequests?.filter(req => req.isRefundable == true);
    },
    refundOrders() {
      let refundOrders = [];
      this.order.ePayRequests?.forEach(req => req.reversedOrders.forEach(ord => refundOrders.push(ord)));
      return refundOrders;
    },
    radioOptions() {
      var options = [];
      var allowedOptions = this.ePayTypeList.filter(
        type => type.description == 'In Person' || type.description == 'Email'
      );
      if (!this.order.allowEPayInPerson) {
        allowedOptions = allowedOptions.filter(x => x.description != 'In Person');
      }
      if (!this.order.allowEPay) {
        allowedOptions = allowedOptions.filter(x => x.description != 'Email');
      }
      allowedOptions.map(requestType => {
        var option = { text: requestType.description, value: requestType.ePayTypeId };
        options.push(option);
      });
      return options;
    },
    refundRadioOptions() {
      let returnedOptions = [];
      let options = [];
      if (this.hasVoidableRequest) {
        options.push(this.ePayTypeList.find(type => type.description == 'Void'));
      }
      if (this.hasRefundableRequests) {
        options.push(this.ePayTypeList.find(type => type.description == 'Refund'));
      }
      options.map(requestType => {
        var option = { text: requestType.description, value: requestType.ePayTypeId };
        returnedOptions.push(option);
      });
      return returnedOptions;
    },
    isPartOrder() {
      if (this.orderId.substring(0, 1) == 'C') return true;
      else {
        return false;
      }
    }
  },
  async created() {
    let branchId = this.orderId.substring(1, 4);
    await this[LookupActions.FETCH_EMPLOYEES_BY_BRANCH](branchId);
  },
  methods: {
    ...mapActions([
      ServiceOrderActions.SEND_CARD_PAYMENT_REQUEST,
      ServiceOrderActions.SEND_PAYMENT_REQUEST,
      ServiceOrderActions.VOID_PAYMENT_REQUEST,
      ServiceOrderActions.REFUND_PAYMENT_REQUEST,
      PartsOrderActions.SEND_CARD_PAYMENT_REQUEST,
      PartsOrderActions.SEND_PAYMENT_REQUEST,
      PartsOrderActions.VOID_PAYMENT_REQUEST,
      PartsOrderActions.REFUND_PAYMENT_REQUEST,
      LookupActions.FETCH_EMPLOYEES_BY_BRANCH
    ]),
    statusBadgeVariant(status) {
      if (status == 1) return 'primary';
      if (status == 2) return 'success';
      if (status == 3) return 'danger';
    },
    statusText(status) {
      if (status == 1) return 'Sent';
      if (status == 2) return 'Paid';
      if (status == 3) return 'Declined';
    },
    getEmployee(empId) {
      return this.employeeList.find(employee => employee.employeeId === empId)?.name;
    },
    async send() {
      //if (this.hasChanges()) {
      //  this.$refs.UnsavedChangesModal.show(this, this.save);
      //} else {
      if (this.selected == 1) {
        this.sending = true;
        try {
          if (this.isPartOrder)
            await this[PartsOrderActions.SEND_PAYMENT_REQUEST]({
              orderId: this.orderId,
              ePayEmail: this.ePayEmail,
              requestedAmount: this.requestedAmount,
              branchId: this.orderId.substring(1, 4)
            });
          else {
            await this[ServiceOrderActions.SEND_PAYMENT_REQUEST]({
              unitId: this.unitId,
              serviceOrderId: this.orderId,
              ePayEmail: this.ePayEmail,
              requestedAmount: this.requestedAmount,
              branchId: this.orderId.substring(1, 4)
            });
          }
          this.ePayEmail = null;
          this.requestedAmount = null;
          SuccessService.createSuccessToast(this.$root, `Payment request sent successfully.`);
        } catch {
          const errorMessage = `Error sending payment request.`;
          ErrorService.createErrorToast(this, errorMessage);
          throw Error(errorMessage);
        } finally {
          this.sending = false;
        }
      } else if (this.selected == 2) {
        this.sending = true;
        try {
          if (this.isPartOrder) {
            await this[PartsOrderActions.SEND_CARD_PAYMENT_REQUEST]({
              orderId: this.orderId,
              ePayEmail: this.ePayEmail,
              requestedAmount: this.requestedAmount,
              branchId: this.orderId.substring(1, 4)
            });
          } else {
            await this[ServiceOrderActions.SEND_CARD_PAYMENT_REQUEST]({
              unitId: this.unitId,
              serviceOrderId: this.orderId,
              ePayEmail: this.ePayEmail,
              requestedAmount: this.requestedAmount,
              branchId: this.branchId
            });
          }
          this.requestedAmount = null;
          SuccessService.createSuccessToast(this.$root, `Payment sent to card reader successfully.`);
        } catch {
          const errorMessage = `Error card present payment.`;
          ErrorService.createErrorToast(this, errorMessage);
          throw Error(errorMessage);
        } finally {
          this.sending = false;
        }
      }
      //},
    },
    async sendReturn() {
      //if (this.hasChanges()) {
      //  this.$refs.UnsavedChangesModal.show(this, this.save);
      //} else {
      if (this.selected == 3) {
        this.sendingReturn = true;
        try {
          if (this.isPartOrder)
            await this[PartsOrderActions.VOID_PAYMENT_REQUEST]({
              orderId: this.orderId,
              voidRequest: this.voidRequest
            });
          else {
            await this[ServiceOrderActions.VOID_PAYMENT_REQUEST]({
              unitId: this.unitId,
              serviceOrderId: this.orderId,
              voidRequest: this.voidRequest
            });
          }
          this.voidRequest = null;
          SuccessService.createSuccessToast(this.$root, `Void Payment sent successfully.`);
        } catch {
          const errorMessage = `Error voiding payment.`;
          ErrorService.createErrorToast(this, errorMessage);
          throw Error(errorMessage);
        } finally {
          this.sendingReturn = false;
        }
      } else if (this.selected == 4) {
        this.sendingReturn = true;
        try {
          if (this.isPartOrder) {
            await this[PartsOrderActions.REFUND_PAYMENT_REQUEST]({
              orderId: this.orderId,
              ePayEmail: this.ePayEmail,
              requestedAmount: this.requestedAmount,
              branchId: this.orderId.substring(1, 4)
            });
          } else {
            await this[ServiceOrderActions.REFUND_PAYMENT_REQUEST]({
              unitId: this.unitId,
              serviceOrderId: this.orderId,
              ePayEmail: this.ePayEmail,
              requestedAmount: this.requestedAmount,
              branchId: this.branchId
            });
          }
          this.refundRequest = null;
          SuccessService.createSuccessToast(this.$root, `Refund Payment sent successfullyy.`);
        } catch {
          const errorMessage = `Error refunding payment.`;
          ErrorService.createErrorToast(this, errorMessage);
          throw Error(errorMessage);
        } finally {
          this.sendingReturn = false;
        }
      }
      //},
    },
    async save(cancel = true) {
      if (!cancel) {
        this.$emit('updateServiceOrder');
      }
    }
  }
};
</script>
<style>
.void-button {
  margin-left: 35px;
}
.reversal {
  color: red;
}
</style>
