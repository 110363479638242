import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, InvoiceActions, InvoiceMutations } from './types';
import { UserGetters } from '@/shared/store/user/types';
import InvoiceService from '@/shared/services/InvoiceService';
import state from '../state';
import moment from 'moment';

const _actions = namespaceHelper.removeNamespace(namespace, InvoiceActions);
const _mutations = namespaceHelper.removeNamespace(namespace, InvoiceMutations);

export default {
  async [_actions.FETCH_FIRE_INVOICE_UI_CONFIG]({ commit }) {
    try {
      const response = await InvoiceService.getFireInvoiceUiConfig();
      const config = response.data;
      commit(_mutations.SET_FIRE_INVOICE_UI_CONFIG, config);
    } catch (error) {
      throw Error(error);
    }
  },
  // Search
  async [_actions.SEARCH_INVOICES]({ commit }, parameters) {
    let response = {};
    let url = state.url;
    if (url.includes('localhost:8050') || url.includes('clarkefire.com')) {
      response = await InvoiceService.searchFireInvoices(parameters);
    } else {
      response = await InvoiceService.searchInvoices(parameters);
    }

    const searchResults = response.data;
    if (parameters.category == 'history') {
      await commit(_mutations.SET_HISTORY_INVOICES, response.data);
    } else if (parameters.category == 'review') {
      await commit(_mutations.SET_REVIEW_INVOICES, response.data);
    } else if (parameters.category == 'reassign') {
      await commit(_mutations.SET_REASSIGN_INVOICES, response.data);
    } else {
      await commit(_mutations.SET_INVOICES, { searchResults, parameters });
    }
    return response.data;
  },
  async [_actions.PUT_REASSIGN_INVOICES](_, parameters) {
    let response = await InvoiceService.putReassignInvoices(parameters);
    return response.data;
  },
  async [_actions.FETCH_INVOICE]({ commit, rootGetters }, parameters) {
    const response = await InvoiceService.fetchInvoice(parameters);
    await commit(_mutations.SET_INVOICE, response.data);
    await commit(_mutations.SET_INVOICE_OBJECTS, response.data[0]);
    let isCUK = rootGetters[UserGetters.IS_CUK];
    if (response.data[0].invoiceDateTime && isCUK) {
      commit(_mutations.SET_DAY, moment(String(response.data[0].invoiceDateTime)).format('DD'));
      commit(_mutations.SET_MONTH, moment(String(response.data[0].invoiceDateTime)).format('MM'));
      commit(_mutations.SET_YEAR, moment(String(response.data[0].invoiceDateTime)).format('YYYY'));
    } else {
      commit(_mutations.SET_DAY, null);
      commit(_mutations.SET_MONTH, null);
      commit(_mutations.SET_YEAR, null);
    }
    return response.data[0];
  },
  async [_actions.FETCH_AP_PROCESSORS]({ commit }) {
    const response = await InvoiceService.fetchAPProcessors();
    await commit(_mutations.SET_AP_PROCESSORS, response.data);
  },
  async [_actions.FETCH_DIVISION_AP_PROCESSORS]({ commit }, params) {
    const response = await InvoiceService.fetchDivisionAPProcessors(params);
    await commit(_mutations.SET_DIVISION_AP_PROCESSORS, response.data);
  },
  async [_actions.FETCH_INVOICE_APPROVERS]({ commit }) {
    const response = await InvoiceService.fetchApprovers();
    await commit(_mutations.SET_INVOICE_APPROVERS, response.data);
  },
  async [_actions.FETCH_INVOICE_DIVISION_APPROVERS]({ commit }, params) {
    const response = await InvoiceService.fetchDivisionApprovers(params);
    await commit(_mutations.SET_INVOICE_DIVISION_APPROVERS, response.data);
  },
  async [_actions.FETCH_TAX_JURISDICTIONS]({ commit }, params) {
    const response = await InvoiceService.fetchTaxJurisdictions(params);
    await commit(_mutations.SET_TAX_JURISDICTIONS, response.data);
  },
  async [_actions.SEARCH_APPROVERS]({ commit }, params) {
    const approverList = await InvoiceService.searchApprovers(params);
    commit(_mutations.SET_INVOICE_DIVISION_APPROVERS, approverList.data);
  },
  async [_actions.SEARCH_VENDORS]({ commit }, params) {
    const vendorsList = await InvoiceService.searchVendors(params);
    commit(_mutations.SET_VENDORS_LIST, vendorsList.data);
  },
  async [_actions.FETCH_POS]({ commit }, params) {
    const vendorPOs = await InvoiceService.searchPOs(params);
    commit(_mutations.SET_POS, vendorPOs.data);
  },
  async [_actions.FETCH_FIRE_POS]({ commit }, params) {
    const vendorPOs = await InvoiceService.searchFirePOs(params);
    commit(_mutations.SET_FIRE_POS, vendorPOs.data);
  },
  async [_actions.SEARCH_ACCOUNTS]({ commit }, params) {
    const accountsList = await InvoiceService.searchAccounts(params);
    commit(_mutations.SET_LINEITEM_PROP, { index: params.index, key: 'accountsList', value: accountsList.data });
  },
  async [_actions.SAVE_INVOICE](_, invoice) {
    await InvoiceService.saveInvoice(invoice);
  },
  async [_actions.APPROVE_INVOICE](_, invoice) {
    await InvoiceService.approveInvoice(invoice);
  },
  async [_actions.REJECT_INVOICE](_, invoice) {
    await InvoiceService.rejectInvoice(invoice);
  },
  async [_actions.REQUEST_INVOICE_APPROVAL](_, invoice) {
    await InvoiceService.requestInvoiceApproval(invoice);
  },
  async [_actions.SEND_INVOICE](_, invoice) {
    await InvoiceService.sendInvoice(invoice);
  },
  async [_actions.VOUCHER_INVOICE](_, invoice) {
    await InvoiceService.voucherInvoice(invoice);
  },
  async [_actions.ADD_NEW_LINE_ITEM]({ commit }, params) {
    let newItemInt = parseInt(params.previousItemNumber) + 10;
    params.newItemNumber = newItemInt.toString().padStart(6, '0');
    commit(_mutations.SET_NEW_LINE_ITEM, params);
  },
  async [_actions.REMOVE_LINE_ITEM]({ commit, state }, index) {
    commit(_mutations.REMOVE_LINE_ITEM, index);
    if (index < state.invoice.length) {
      commit(_mutations.UPDATE_LINE_ITEM_NUMBERS);
    }
  },
  async [_actions.REMOVE_INVOICE]({ commit }, index) {
    commit(_mutations.REMOVE_INVOICE, index);
  },
  async [_actions.VALIDATE_FULL_INVOICE](_, invoice) {
    await InvoiceService.validateFullInvoice(invoice);
  },
  async [_actions.VALIDATE_HEADER_INVOICE](_, invoice) {
    await InvoiceService.validateHeaderInvoice(invoice);
  },
  async [_actions.FETCH_APP_E_URL]({ commit }, params) {
    const response = await InvoiceService.fetchAppEUrl(params);
    commit(_mutations.SET_APP_E_URL, response.data);
  },
  async [_actions.DOWNLOAD_PDF](_, params) {
    let result = await InvoiceService.fetchDownload(params);
    return result.data;
  },
  async [_actions.RUN_DATA_SYNC](_, param) {
    const response = await InvoiceService.runDataSync(param);
    return response.data;
  },
  async [_actions.RUN_APPE_IMPORT](_, param) {
    const response = await InvoiceService.runAppeImport(param);
    return response.data;
  },
  async [_actions.RUN_CORPAY]() {
    const response = await InvoiceService.runCorPay();
    return response.data;
  },
  async [_actions.DOWNLOAD_EDI_EXCEPTIONS_REPORT_BY_VENDOR](_, params) {
    let response = await InvoiceService.downloadEDIExceptionsReportByVendor(params);
    return response.data;
  },
  async [_actions.FETCH_DATA_SYNC]() {
    const response = await InvoiceService.fetchDataSync();
    return response.data;
  },
  async [_actions.FETCH_EDI_VENDORS]({ commit }) {
    const response = await InvoiceService.fetchEDIVendors();
    await commit(_mutations.SET_EDI_VENDORS, response.data);
  },
  async [_actions.FETCH_INVOICES_COUNT]({ commit }) {
    let url = state.url;
    var isFire = false;
    if (url.includes('localhost:8050') || url.includes('clarkefire.com')) {
      isFire = true;
    }
    const count = await InvoiceService.getInvoicesCount(isFire);
    commit(_mutations.SET_INVOICES_COUNT, count.data);
  }
};
