<script>
import EPaymentService from '@/shared/services/EPaymentService';

export default {
  name: 'EPayProcess',
  computed: {
    company() {
      return this.$route.query.ssl_company;
    },
    amount() {
      return this.$route.query.ssl_amount;
    },
    cardName() {
      return this.$route.query.ssl_card_short_description;
    },
    address() {
      return this.$route.query.ssl_avs_address;
    },
    email() {
      return this.$route.query.ssl_email;
    },
    zipCode() {
      return this.$route.query.ssl_avs_zip;
    },
    expDate() {
      return this.$route.query.ssl_exp_date;
    },
    city() {
      return this.$route.query.ssl_city;
    },
    invoiceNumber() {
      return this.$route.query.ssl_invoice_number;
    },
    transactionId() {
      return this.$route.query.ssl_txn_id;
    },
    transactionType() {
      return this.$route.query.ssl_transaction_type;
    },
    result() {
      return this.$route.query.ssl_result;
    },
    resultMessage() {
      return this.$route.query.ssl_result_message;
    },
    cardNumber() {
      return this.$route.query.ssl_card_number;
    },
    bankAccountNumber() {
      return this.$route.query.ssl_bank_account_number;
    },
    transactionTime() {
      return this.$route.query.ssl_txn_time;
    },
    approvalCode() {
      return this.$route.query.ssl_approval_code;
    },
    requestId() {
      return this.$route.query.ssl_merchant_txn_id;
    },
    cardType() {
      return this.$route.query.ssl_card_type;
    }
  },
  created() {
    this.processSuccess();
  },
  beforeDestroy() {},
  methods: {
    async processSuccess() {
      let successVM = {
        Company: this.company,
        Amount: this.amount,
        Card: this.cardName,
        Address: this.address,
        Email: this.email,
        ZipCode: this.zipCode,
        ExpDate: this.expDate,
        City: this.City,
        InvoiceNumber: this.invoiceNumber,
        TransactionId: this.transactionId,
        TransactionType: this.transactionType,
        Result: this.result,
        ResultMessage: this.resultMessage,
        CardNumber: this.cardNumber,
        BankAccountNumber: this.bankAccountNumber,
        TransactionTime: this.transactionTime,
        ApprovalCode: this.approvalCode,
        RequestId: this.requestId,
        CardType: this.cardType
      };

      await EPaymentService.processSuccess(successVM);
    }
  }
};
</script>
