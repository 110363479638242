import axios from 'axios';

const PARTS_ORDER_PATH = '/api/parts-orders';
const SEARCH_PATH_ENDING = '/search';
const EXPORT_PATH = '/export';
const E_PAY_ENDING = 'epay';
const E_CARD_PAY_ENDING = 'epay-card';
const E_PAY_VOID_ENDING = 'epay-void';
const E_PAY_REFUND_ENDING = 'epay-refund';
class PartsOrderService {
  async fetchPartsOrder(partsOrderId) {
    const url = `${PARTS_ORDER_PATH}/${encodeURIComponent(partsOrderId)}`;
    const requestedParts = await axios.get(url);
    return requestedParts;
  }

  async searchPartsOrders(parameters) {
    const url = `${PARTS_ORDER_PATH}${SEARCH_PATH_ENDING}`;
    const result = await axios.get(url, {
      params: parameters
    });
    return result;
  }
  async exportPartsOrderSearchResults(parameters) {
    const url = `${PARTS_ORDER_PATH}${EXPORT_PATH}`;
    const result = await axios.post(url, parameters, {
      responseType: 'blob'
    });
    return result;
  }
  async sendPaymentRequest(params) {
    const url = `${PARTS_ORDER_PATH}/${encodeURIComponent(params.partOrderId)}/${E_PAY_ENDING}`;
    const updatePartOrderResponse = axios.put(url, params.ePayRequest);
    return updatePartOrderResponse;
  }
  async sendCardPaymentRequest(params) {
    const url = `${PARTS_ORDER_PATH}/${encodeURIComponent(params.partOrderId)}/${E_CARD_PAY_ENDING}`;
    const updatePartOrderResponse = axios.put(url, params.ePayRequest);
    return updatePartOrderResponse;
  }
  async voidPaymentRequest(params) {
    const url = `${PARTS_ORDER_PATH}/${encodeURIComponent(params.partOrderId)}/${E_PAY_VOID_ENDING}`;
    const updatePartOrderResponse = axios.put(url, params.ePayRequest);
    return updatePartOrderResponse;
  }
  async refundPaymentRequest(params) {
    const url = `${PARTS_ORDER_PATH}/${encodeURIComponent(params.partOrderId)}/${E_PAY_REFUND_ENDING}`;
    const updatePartOrderResponse = axios.put(url, params.ePayRequest);
    return updatePartOrderResponse;
  }
}

export default new PartsOrderService(); // singleton object
