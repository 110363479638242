<template>
  <div class="px-2">
    <div class="d-flex justify-content-center py-5">
      <img
        src="/images/dual-branding.svg"
        :width="$smallScreen ? 200 : 500"
        height="auto"
        class=""
        alt="Vehicare Fleet Solutions"
      />
    </div>
    <div v-if="!loading" class="d-flex justify-content-center py-5">
      Credit card payment has failed. Please contact the branch {{ branchData.branch }} at
      {{ formattedPhoneNumber(branchData.phone) }} for further assistance.
    </div>
    <div v-if="errorLoading" class="d-flex justify-content-center py-5">
      Credit card payment has failed. Please contact the branch listed in the email for further assistance.
    </div>
  </div>
</template>

<script>
import EPaymentService from '@/shared/services/EPaymentService';
import ErrorService from '@/shared/services/ErrorService';

export default {
  name: 'EPayDeclineResponse',
  data() {
    return {
      branchData: {
        branch: '',
        phone: ''
      },
      loading: true,
      errorLoading: false
    };
  },
  computed: {
    company() {
      return this.$route.query.ssl_company;
    },
    amount() {
      return this.$route.query.ssl_amount;
    },
    cardName() {
      return this.$route.query.ssl_card_short_description;
    },
    address() {
      return this.$route.query.ssl_avs_address;
    },
    email() {
      return this.$route.query.ssl_email;
    },
    zipCode() {
      return this.$route.query.ssl_avs_zip;
    },
    expDate() {
      return this.$route.query.ssl_exp_date;
    },
    city() {
      return this.$route.query.ssl_city;
    },
    invoiceNumber() {
      return this.$route.query.ssl_invoice_number;
    },
    transactionId() {
      return this.$route.query.ssl_txn_id;
    },
    transactionType() {
      return this.$route.query.ssl_transaction_type;
    },
    result() {
      return this.$route.query.ssl_result;
    },
    resultMessage() {
      return this.$route.query.ssl_result_message;
    },
    cardNumber() {
      return this.$route.query.ssl_card_number;
    },
    bankAccountNumber() {
      return this.$route.query.ssl_bank_account_number;
    },
    transactionTime() {
      return this.$route.query.ssl_txn_time;
    },
    requestId() {
      return this.$route.query.ssl_merchant_txn_id;
    }
  },
  created() {
    this.processDenial();
  },
  beforeDestroy() {},
  methods: {
    formattedPhoneNumber(value) {
      if (value?.length === 10) {
        return '(' + value.substring(0, 3) + ') ' + value.substring(3, 6) + '-' + value.substring(6, 11);
      } else {
        return value;
      }
    },
    async processDenial() {
      let deniedVM = {
        Company: this.company,
        Amount: this.amount,
        Card: this.cardName,
        Address: this.address,
        Email: this.email,
        ZipCode: this.zipCode,
        ExpDate: this.expDate,
        City: this.City,
        InvoiceNumber: this.invoiceNumber,
        TransactionId: this.transactionId,
        TransactionType: this.transactionType,
        Result: this.result,
        ResultMessage: this.resultMessage,
        CardNumber: this.cardNumber,
        BankAccountNumber: this.bankAccountNumber,
        TransactionTime: this.transactionTime,
        RequestId: this.requestId
      };

      try {
        let result = await EPaymentService.processDenial(deniedVM);
        this.branchData = result.data;
        this.loading = false;
      } catch (error) {
        const errorMessage = `Error retrieving branch data.`;
        ErrorService.createErrorToast(this, errorMessage);
        this.errorLoading = true;
        throw Error(errorMessage);
      }
    }
  }
};
</script>
