import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, ServiceOrderActions, ServiceOrderMutations, ServiceOrderGetters } from './types';
import { FORM_STAGES } from './state';
import { UnitMutations } from '@/shared/store/unit/types';
import BranchService from '@/shared/services/BranchService';
import JobService from '@/shared/services/JobService';
import CustomerService from '@/shared/services/CustomerService';
import ServiceOrderService from '@/shared/services/ServiceOrderService';
import ErrorService from '@/shared/services/ErrorService';
import UnitService from '@/shared/services/UnitService';
import UnitServiceOrderService from '@/shared/services/UnitServiceOrderService';
import RepairContactService from '@/shared/services/RepairContactService';
import PrintService from '@/shared/services/PrintService';
import Cloner from '@/shared/helpers/cloner';
import { generateThreeCs } from '@/shared/helpers/service-order-helper';
import { LookupGetters } from '@/shared/store/lookup/types';
import { UnitGetters } from '@/shared/store/unit/types';
import { JOB_OPERATION_STATUS_IDS } from '@/shared/store/lookup/state';

const _actions = namespaceHelper.removeNamespace(namespace, ServiceOrderActions);
const _mutations = namespaceHelper.removeNamespace(namespace, ServiceOrderMutations);
const _getters = namespaceHelper.removeNamespace(namespace, ServiceOrderGetters);

export default {
  [_actions.RESET_STATE]({ commit }) {
    commit(_mutations.RESET_STATE);
  },

  async [_actions.DEFAULT_PROP]({ commit, state }, parameters) {
    const key = parameters.key;
    const value = parameters.value;
    commit(_mutations.SET_PROP, { key, value });
    commit(_mutations.SET_OLD_ESTIMORDER_PROP, { estimorder: state.estimorder, key, value });
  },

  // Lookups
  async [_actions.FETCH_CUSTOMER]({ commit }, customerId) {
    const customer = await CustomerService.getCustomer(customerId);
    commit(_mutations.SET_CUSTOMER, customer.data);
    commit(_mutations.SET_DEFAULT_SALES_TYPE_ID, customer.data.billToCustomer.salesTypeId);
  },
  async [_actions.FETCH_TECH_LIST]({ commit, state }) {
    const techList = await BranchService.getTechs(state[state.estimorder].branchId);
    commit(_mutations.SET_TECH_LIST, techList.data);
  },

  // Export
  async [_actions.EXPORT](state, parameters) {
    const exportResults = await ServiceOrderService.exportServiceOrders(parameters);
    return exportResults.data;
  },

  async [_actions.FETCH_CUSTOMER_SPECIFIC_REPAIR_REASONS]({ commit }, billToCustomerId) {
    var repairReasons = await ServiceOrderService.getCustomerSpecificRepairReasons(billToCustomerId);
    commit(_mutations.SET_CUSTOMER_SPECIFIC_REPAIR_REASONS, repairReasons.data);
  },

  async [_actions.FETCH_BRANCH_SPECIFIC_MISC_CODES]({ commit }, branchId) {
    var miscCodes = await BranchService.getBranchSpecificMiscCodes(branchId);
    commit(_mutations.SET_BRANCH_SPECIFIC_MISC_CODES, miscCodes.data);
  },

  // Service Order
  async [_actions.FETCH_SERVICE_ORDER_PRICING]({ commit }, params) {
    let result = await ServiceOrderService.getServiceOrderPricing(params);
    commit(_mutations.SET_SERVICE_ORDER_PRICING, result.data);
    return result;
  },
  async [_actions.FETCH_SERVICE_ORDER]({ commit, dispatch }, params) {
    let serviceOrderResult;
    serviceOrderResult = await UnitService.getServiceOrder(params.unitId, params.serviceOrderId);
    const serviceOrder = serviceOrderResult.data;

    await dispatch(_actions.SET_SERVICE_ORDER, {
      serviceOrder,
      _showDetails: false
    });
    let estimorder = serviceOrder.isEstimate ? 'estimate' : 'serviceOrder';

    commit(_mutations.SET_ESTIMORDER, estimorder);
    commit(_mutations.SET_FORM_STAGE, FORM_STAGES.EDIT_SO);
  },
  async [_actions.SET_SERVICE_ORDER]({ commit, dispatch, getters }, params) {
    const serviceOrder = params.serviceOrder;
    let estimorder = serviceOrder.isEstimate ? 'estimate' : 'serviceOrder';
    commit(_mutations.SET_AUDIT_RUN_ERROR, serviceOrder.auditRunError);
    if (estimorder == 'serviceOrder') {
      let isOpenServiceOrder = serviceOrder.serviceOrderId?.indexOf(':') < 0;
      commit(_mutations.SET_IS_OPEN_SERVICE_ORDER, isOpenServiceOrder);
    }
    commit(_mutations.SET_SERVICE_ORDER_WITHOUT_JOBS, { estimorder, serviceOrder });
    commit(_mutations.SYNC_PO, serviceOrder);
    if (serviceOrder.jobs) {
      if (typeof serviceOrder.jobs === 'object') serviceOrder.jobs = Object.values(serviceOrder.jobs);
      const jobs = {};
      for (let job of serviceOrder.jobs) {
        const jobKey = JSON.stringify({ jobId: job.jobId, operationId: job.operationId });
        jobs[jobKey] = { ...job };
        jobs[jobKey]._showDetails =
          params._showDetails != null ? params._showDetails : getters[_getters.GET_SHOW_DETAILS](jobKey);
        if (!this._vm_$isCustomer) {
          const matchedOperation = await dispatch(_actions.FIND_MATCHING_OPERATION, job);
          const matchedJob = matchedOperation.matchedJob;
          if (matchedJob) {
            commit(UnitMutations.SET_SERVICE_ORDER_JOBS, matchedOperation, { root: true });
            jobs[jobKey]._stdOp = Cloner.shallowClone(matchedJob);
          }
        }
        for (let labor of job.details.laborLines) {
          labor._showClock = false;
        }
      }
      if (this._vm.$isCustomer || this._vm.$isMobileApp) {
        commit(_mutations.SET_JOBS, { estimorder, jobs });
      } else {
        await dispatch(_actions.ASSIGN_JOBS, { estimorder, jobs });
      }
      if (estimorder == 'estimate') {
        commit(_mutations.SET_OLD_JOBS, { estimorder, jobs });
      }
    }
    commit(_mutations.SET_OLD_ESTIMORDER, estimorder);
  },
  async [_actions.PRINT_FORM]({ state }, printFormId) {
    var id = state[state.estimorder].serviceOrderId;
    if (printFormId == '11' && state[state.estimorder].estimateId != null && state[state.estimorder].isEstimate) {
      id = state[state.estimorder].estimateId;
    }
    const parameters = {
      serviceOrderId: id,
      printFormId
    };
    const operationsForecastExportResult = await PrintService.printForm(parameters);
    return operationsForecastExportResult.data;
  },

  async [_actions.CLONE_SERVICE_ORDER]({ commit, dispatch, state, rootGetters }, params) {
    let serviceOrderResult;
    try {
      serviceOrderResult = await UnitService.getServiceOrder(params.unitId, params.serviceOrderId, true);
    } catch (error) {
      throw new Error(`No Service Order with ID ${params.serviceOrderId}`);
    }
    let uiConfig = rootGetters[LookupGetters.GET_UI_CONFIG];
    let filteredJobs = serviceOrderResult.data.jobs.filter(j =>
      uiConfig.jobsFilteredBeforeCloning.some(job => j.jobId.includes(job))
    );

    filteredJobs.forEach(j => serviceOrderResult.data.jobs.splice(serviceOrderResult.data.jobs.indexOf(j), 1));

    const serviceOrder = serviceOrderResult.data;
    // Set SO Header
    commit(_mutations.SET_BRANCH, serviceOrder.branchId);

    if (serviceOrder.repairPriorityId) {
      commit(_mutations.SET_REPAIR_PRIORITY, serviceOrder.repairPriorityId);
    }

    if (serviceOrder.repairReasonId) {
      commit(_mutations.SET_REPAIR_REASON, serviceOrder.repairReasonId);
    }

    commit(_mutations.SET_SALES_TYPE_ID, serviceOrder.salesTypeId);

    // Add Jobs
    const assignedJobs = {};
    for (let job of serviceOrder.jobs) {
      const matchedOperation = await dispatch(_actions.FIND_MATCHING_OPERATION, job);
      const matchedJob = matchedOperation.matchedJob;

      commit(UnitMutations.SET_SERVICE_ORDER_JOBS, matchedOperation, { root: true });
      const jobKey = JSON.stringify({ jobId: job.jobId, operationId: job.operationId });
      assignedJobs[jobKey] = {
        jobId: job.jobId,
        operationId: job.operationId,
        description: job.description,
        ...generateThreeCs(matchedJob),
        vmrsCode: job.vmrsCode,
        repairReasonId: job.repairReasonId,
        details: {
          laborLines: job.details.laborLines,
          parts: job.details.parts,
          subletLines: job.details.subletLines,
          miscLines: job.details.miscLines,
          commentLines: job.details.commentLines
        },
        _showDetails: false
      };
      for (let labor of job.details.laborLines) {
        labor.technician = null;
      }
      assignedJobs[jobKey]._stdOp = Cloner.shallowClone(matchedOperation);
    }
    await dispatch(_actions.ASSIGN_JOBS, { estimorder: state.estimorder, jobs: assignedJobs });

    commit(_mutations.SET_FORM_STAGE, FORM_STAGES.EDIT_HEADER);
  },
  async [_actions.CREATE_SERVICE_ORDER]({ dispatch, commit, getters }, parameters) {
    const unitId = parameters.unitId;
    const unitServiceOrder = getters[_getters.GET_SERVICE_ORDER_TO_SAVE];
    if (parameters.clone) unitServiceOrder.clone = true;
    const createServiceOrderResponse = await UnitService.createServiceOrder({ unitId, unitServiceOrder });
    const serviceOrder = createServiceOrderResponse.data;
    let estimorder = unitServiceOrder.isEstimate ? 'estimate' : 'serviceOrder';

    commit(_mutations.SET_JOBS, { estimorder, jobs: {} });
    await dispatch(_actions.SET_SERVICE_ORDER, {
      serviceOrder,
      _showDetails: false
    });
    return serviceOrder;
  },
  async [_actions.CREATE_LABOR_TIME_PUNCH_IN](state, parameters) {
    let laborTimePunchResponse = await UnitService.createLaborTimePunchIn(parameters);
    const laborTimePunch = laborTimePunchResponse.data;
    return laborTimePunch;
  },
  async [_actions.CREATE_LABOR_TIME_PUNCH_OUT]({ getters }, parameters) {
    const svcOrder = getters[_getters.GET_SERVICE_ORDER];
    parameters.unitId = svcOrder.unitId;
    parameters.serviceOrderId = svcOrder.serviceOrderId;
    let laborTimePunchResponse = await UnitService.createLaborTimePunchOut(parameters);
    const laborTimePunch = laborTimePunchResponse.data;
    return laborTimePunch;
  },
  async [_actions.COMPLETE_UNIT_SERVICE_ORDER_LABOR]({ getters }, parameters) {
    const svcOrder = getters[_getters.GET_SERVICE_ORDER];
    parameters.unitId = svcOrder.unitId;
    parameters.serviceOrderId = svcOrder.serviceOrderId;
    let completeLaborResponse = await UnitService.completeUnitServiceOrderLabor(parameters);
    const laborTimePunch = completeLaborResponse.data;
    return laborTimePunch;
  },
  async [_actions.SAVE_LABOR_TECH_REMARKS](state, params) {
    let techRemarksResponse = await UnitService.saveUnitServiceOrderLaborTechRemarks(params);
    let timeClockData = techRemarksResponse.data;
    return timeClockData;
  },
  async [_actions.FETCH_UPDATE_JOB_LABOR_TECH_REMARKS]({ commit }, parameters) {
    let techRemarksResponse = await UnitService.getJobLaborTechRemarks(parameters);
    let techRemarks = techRemarksResponse.data;
    let params = { jobKey: parameters.jobKey, techRemarks };
    commit(_mutations.SET_JOB_LABOR_TECH_REMARKS, params);
  },
  async [_actions.CREATE_OTHER_ESTIMORDER]({ dispatch, commit, getters }, parameters) {
    const unitId = parameters.unitId;
    const unitServiceOrder = Cloner.deepClone(getters[_getters.GET_SERVICE_ORDER_TO_SAVE]);

    if (unitServiceOrder.isEstimate && !parameters.bringApprovedJobs) {
      unitServiceOrder.jobs = [];
    } else if (unitServiceOrder.isEstimate && parameters.bringApprovedJobs) {
      unitServiceOrder.jobs = unitServiceOrder.jobs.filter(
        job => job.jobOperationStatusId == JOB_OPERATION_STATUS_IDS.APPROVED
      );
      unitServiceOrder.jobs.forEach(job => {
        job.details.laborLines.forEach(ll => {
          ll.hoursFlat = ll.hoursBill;
          ll.hoursBill = 0;
        });
      });
      unitServiceOrder.jobs.forEach(job => (job.linkedOperationId = job.operationId));
      unitServiceOrder.jobs.forEach(job => (job.serviceOrderId = null));
      unitServiceOrder.isEstimateWithApprovedJobs = true;
    } else {
      delete unitServiceOrder.breakdownId;
      for (let job of unitServiceOrder.jobs) {
        job.jobOperationStatusId = JOB_OPERATION_STATUS_IDS.APPROVED;
        job.linkedOperationId = job.operationId;
        job.details.inspections = [];
      }
    }

    unitServiceOrder.estimateId = getters[_getters.GET_SERVICE_ORDER_ID];
    unitServiceOrder.isEstimate = !unitServiceOrder.isEstimate;

    const createServiceOrderResponse = await UnitService.createServiceOrder({ unitId, unitServiceOrder });
    const serviceOrder = createServiceOrderResponse.data;
    let estimorder = unitServiceOrder.isEstimate ? 'estimate' : 'serviceOrder';

    commit(_mutations.SET_JOBS, { estimorder, jobs: {} });
    await dispatch(_actions.SET_SERVICE_ORDER, {
      serviceOrder,
      _showDetails: false
    });
    return serviceOrder;
  },

  async [_actions.UPDATE_SERVICE_ORDER]({ dispatch, getters }, parameters) {
    const unitId = parameters.unitId;
    const serviceOrderId = parameters.serviceOrderId;
    const unitServiceOrder = getters[_getters.GET_SERVICE_ORDER_TO_SAVE];
    const createServiceOrderResponse = await UnitService.updateServiceOrder({
      unitId,
      serviceOrderId,
      unitServiceOrder
    });
    const serviceOrder = createServiceOrderResponse.data;
    await dispatch(_actions.SET_SERVICE_ORDER, {
      serviceOrder
    });
    return serviceOrder;
  },

  async [_actions.CALCULATE_SO_TAXES]({ commit }, { unitId, serviceOrderId }) {
    try {
      commit(_mutations.SET_CALCULATING_TAXES, true);
      const response = await UnitServiceOrderService.calculateTaxes({
        unitId,
        serviceOrderId
      });
      const serviceOrder = response.data;

      commit(_mutations.SET_TAXES, serviceOrder);
    } finally {
      commit(_mutations.SET_CALCULATING_TAXES, false);
    }
  },

  async [_actions.UPDATE_BILL_TO_CUSTOMER]({ commit, getters, state }, parameters) {
    const unitId = parameters.unitId;
    const serviceOrderId = parameters.serviceOrderId;
    const unitServiceOrder = getters[_getters.GET_SERVICE_ORDER_TO_SAVE];
    const createServiceOrderResponse = await UnitService.partialUpdateServiceOrder({
      unitId,
      serviceOrderId,
      unitServiceOrder: {
        billToCustomerId: unitServiceOrder.billToCustomerId,
        timestampSVSLS: unitServiceOrder.timestampSVSLS
      }
    });
    const serviceOrder = createServiceOrderResponse.data;
    let payload = { key: 'billToCustomer', value: serviceOrder.billToCustomer };
    commit(_mutations.SET_PROP, payload);
    commit(_mutations.SET_OLD_ESTIMORDER_PROP, { estimorder: state.estimorder, ...payload });
    payload = { key: 'salesTypeId', value: serviceOrder.salesTypeId };
    commit(_mutations.SET_PROP, payload);
    commit(_mutations.SET_OLD_ESTIMORDER_PROP, { estimorder: state.estimorder, ...payload });
    payload = { key: 'subtotal', value: serviceOrder.subtotal };
    commit(_mutations.SET_PROP, payload);
    commit(_mutations.SET_OLD_ESTIMORDER_PROP, { estimorder: state.estimorder, ...payload });
    payload = { key: 'shopSupplies', value: serviceOrder.shopSupplies };
    commit(_mutations.SET_PROP, payload);
    commit(_mutations.SET_OLD_ESTIMORDER_PROP, { estimorder: state.estimorder, ...payload });
    payload = { key: 'total', value: serviceOrder.total };
    commit(_mutations.SET_PROP, payload);
    commit(_mutations.SET_OLD_ESTIMORDER_PROP, { estimorder: state.estimorder, ...payload });
    payload = { key: 'epaCharge', value: serviceOrder.epaCharge };
    commit(_mutations.SET_PROP, payload);
    commit(_mutations.SET_OLD_ESTIMORDER_PROP, { estimorder: state.estimorder, ...payload });
    payload = { key: 'salesTax', value: serviceOrder.salesTax };
    commit(_mutations.SET_PROP, payload);
    commit(_mutations.SET_OLD_ESTIMORDER_PROP, { estimorder: state.estimorder, ...payload });
    commit(_mutations.SET_TIMESTAMP, serviceOrder.timestampSVSLS);
    commit(_mutations.SET_JOB_TIMESTAMP, serviceOrder.jobs);
    return serviceOrder;
  },

  async [_actions.SEARCH_SERVICE_ORDERS]({ commit }, parameters) {
    parameters.isEstimate = false;
    const orders = await ServiceOrderService.searchOrders(parameters);
    const results = orders.data.map(result => ({
      ...result,
      repairStatus: result.invoiced ? 'Invoiced' : result.repairStatus ? result.repairStatus : 'Working'
    }));
    commit(_mutations.SET_SO_SEARCH_RESULTS, results);
  },

  async [_actions.SEARCH_ESTIMATES]({ commit }, parameters) {
    parameters.isEstimate = true;
    const response = await ServiceOrderService.searchOrders(parameters);
    const results = response.data;
    commit(_mutations.SET_EST_SEARCH_RESULTS, results);
  },

  // JOBS
  async [_actions.ADD_JOBS_TO_SERVICE_ORDER]({ dispatch, commit, state }, parameters) {
    let estimorder = parameters.isEstimate ? 'estimate' : 'serviceOrder';

    const addJobsResponse = await UnitService.addJobsToServiceOrder(parameters);
    const newJobs = addJobsResponse.data.jobs;
    const timestampSVSLS = addJobsResponse.data.timestampSVSLS;
    if (newJobs) {
      const jobs = {};
      for (let job of newJobs) {
        const jobKey = JSON.stringify({ jobId: job.jobId, operationId: job.operationId });
        jobs[jobKey] = { ...job };
        jobs[jobKey]._showDetails = false;
      }
      const assignedJobs = await dispatch(_actions.ASSIGN_JOBS, { estimorder, jobs });
      var key = 'jobs';
      var value = { ...state['old' + estimorder].jobs, ...assignedJobs };
      commit(_mutations.SET_OLD_ESTIMORDER_PROP, { estimorder, key, value });
      key = 'oldJobs';
      value = { ...state['old' + estimorder].oldJobs, ...assignedJobs };
      commit(_mutations.SET_OLD_ESTIMORDER_PROP, { estimorder, key, value });
      commit(_mutations.SET_TIMESTAMP, timestampSVSLS);
    }
  },

  // Must be called after data is saved/updated in Excede
  // operationId is the key
  async [_actions.ASSIGN_JOBS]({ commit }, params) {
    const jobs = params.jobs;
    const estimorder = params.estimorder;
    const newJobKeys = Object.keys(jobs);
    const newJobIds = Object.values(jobs).map(job => job.jobId);
    if (newJobKeys.length > 0) {
      const response = await JobService.getLaborCodes(newJobIds);
      for (let laborCode of response.data) {
        const jobKeys = newJobKeys.filter(key => JSON.parse(key).jobId == laborCode.jobId);
        for (let jobKey of jobKeys) {
          jobs[jobKey].laborCode = laborCode;
        }
      }
      commit(_mutations.ADD_JOBS, { estimorder, jobs });
      const jobsClone = Cloner.deepClone(jobs);
      commit(_mutations.ADD_OLD_JOBS, { estimorder, jobs: jobsClone });
      return jobs;
    }
  },

  async [_actions.FIND_MATCHING_OPERATION]({ rootGetters }, job) {
    var matchedExcedeJob = {};
    var matchedJob = {};
    if (job.jobId.substring(0, 1) == '&') {
      matchedExcedeJob = rootGetters[LookupGetters.GET_STANDARD_OPERATION_EXCEDE_JOBS_MAP][job.jobId];
      if (!matchedExcedeJob) matchedExcedeJob = job;
      matchedJob = rootGetters[UnitGetters.GET_UNIT_STANDARD_OPERATIONS].find(
        stdOp => stdOp.standardOperationId && matchedExcedeJob.standardOperationIds?.includes(stdOp.standardOperationId)
      );
      if (!matchedJob) matchedJob = { excedeJob: job.jobId };
    } else {
      matchedExcedeJob = job;
      matchedJob = { excedeJob: job.jobId };
    }
    return { matchedJob, matchedExcedeJob };
  },

  async [_actions.REMOVE_JOB]({ commit, state }, params) {
    const jobId = params.jobId;
    const operationId = params.operationId;
    const unitId = params.unitId;
    commit(_mutations.SET_FORM_STAGE, FORM_STAGES.REMOVING_JOB);
    try {
      await UnitService.deleteJobFromServiceOrder({
        unitId,
        serviceOrderId: state[state.estimorder].serviceOrderId,
        jobId,
        operationId,
        branchId: state[state.estimorder].branchId
      });
      const jobKey = JSON.stringify({ jobId, operationId });
      commit(_mutations.REMOVE_JOB, jobKey);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(_mutations.SET_FORM_STAGE, FORM_STAGES.EDIT_SO);
    }
  },
  // Labor
  async [_actions.ADD_LABOR_LINE]({ commit, getters, state }, { jobKey }) {
    commit(_mutations.ADD_LABOR_LINE, jobKey);
    const defaultTech = getters[_getters.GET_DEFAULT_TECH];
    const index = state[state.estimorder].jobs[jobKey].details.laborLines.length - 1;
    if (defaultTech) {
      commit(_mutations.SET_TECH, { jobKey, index, technician: defaultTech });
    }
  },
  async [_actions.DUPLICATE_LABOR_LINE]({ commit, getters, state }, { jobKey, laborLine }) {
    commit(_mutations.ADD_LABOR_LINE, jobKey);
    const defaultTech = getters[_getters.GET_DEFAULT_TECH];
    const index = state[state.estimorder].jobs[jobKey].details.laborLines.length - 1;
    if (defaultTech) {
      commit(_mutations.SET_TECH, { jobKey, index, technician: defaultTech });
    }
    let { laborCode, description, price, hoursBill, hoursFlat } = laborLine;
    commit(_mutations.SET_LABOR_CODE, { jobKey, index, laborCode });
    commit(_mutations.SET_LABOR_DESCRIPTION, { jobKey, index, description });
    commit(_mutations.SET_LABOR_PRICE, { jobKey, index, price });
    commit(_mutations.SET_HOURS_BILL, { jobKey, index, hoursBill });
    commit(_mutations.SET_HOURS_FLAT, { jobKey, index, hoursFlat });
  },
  async [_actions.SEARCH_LABOR_CODES]({ commit }, search) {
    const laborCodesList = await JobService.searchLaborCodes(search);
    commit(_mutations.SET_LABOR_CODES_LIST, laborCodesList.data);
  },
  async [_actions.SELECT_LABOR_CODES]({ commit }, params) {
    const { data: laborDefault } = await UnitService.getLaborItemDefaults(params);

    commit(_mutations.SET_LABOR_CODE, params);
    commit(_mutations.SET_LABOR_DESCRIPTION, params);
    commit(_mutations.SET_HOURS_FLAT, params);
    commit(_mutations.SET_LABOR_PRICE, { jobKey: params.jobKey, index: params.index, price: laborDefault.price });
    commit(_mutations.SET_LABOR_SUGGESTED_PRICE, {
      jobKey: params.jobKey,
      index: params.index,
      suggestedPrice: laborDefault.price
    });
    commit(_mutations.SET_LABOR_CODES_LIST, []);
  },
  // Parts
  async [_actions.SEARCH_PARTS]({ commit }, params) {
    const partList = await BranchService.getParts(params.branchId, params.search);
    commit(_mutations.SET_PART_LIST, partList.data);
  },
  async [_actions.SELECT_PART]({ commit, dispatch, state }, params) {
    params.unitId = state[state.estimorder].unitId;
    const jobKey = params.jobKey;
    const partIndex = params.partIndex;
    const part = params.part;
    if (jobKey) {
      commit(_mutations.SET_PART, { jobKey, partIndex, part });
      commit(_mutations.SET_PART_SEARCH, { jobKey, partIndex, value: false });
      await dispatch(_actions.FETCH_PART_PRICE, params);
      commit(_mutations.SET_PART_LIST, []);
    } else {
      commit(_mutations.SET_REQUESTED_PART, part);
    }
  },
  async [_actions.FETCH_PART_PRICE]({ state, commit, getters }, params) {
    const jobKey = params.jobKey;
    const partIndex = params.partIndex;
    const part = params.part;
    const unitId = state[state.estimorder].unitId;
    commit(_mutations.TOGGLE_PART_LOADING, { jobKey, partIndex });
    try {
      const branchId = state[state.estimorder].branchId;
      const billToCustomerId = getters[_getters.GET_BILL_TO_CUSTOMER].customerId;
      const partQuoteResponse = await BranchService.getPartQuote(branchId, billToCustomerId, unitId, part);
      const partQuote = partQuoteResponse.data[0]; // So far always comes back as array of 1
      if (partQuote.availableParts?.length > 0) {
        commit(_mutations.SET_AVAILABLE_PARTS, partQuote.availableParts);
      }
      commit(_mutations.SET_PART_QUOTE, {
        jobKey,
        partIndex,
        price: partQuote.priceServiceOrder,
        cost: partQuote.cost,
        isWarrantyAlert: partQuote.isWarrantyAlert
      });
    } catch (error) {
      commit(_mutations.SET_PART_QUOTE, {
        jobKey,
        partIndex,
        price: null,
        cost: null
      });
    }
    commit(_mutations.SET_PART_MARGIN, { jobKey, partIndex });
    commit(_mutations.TOGGLE_PART_LOADING, { jobKey, partIndex });
  },
  async [_actions.SET_PART_PRICE]({ commit }, params) {
    const jobKey = params.jobKey;
    const partIndex = params.partIndex;
    const price = params.price;

    commit(_mutations.SET_PART_PRICE, { jobKey, partIndex, price });
    commit(_mutations.SET_PART_MARGIN, { jobKey, partIndex });
  },
  async [_actions.FETCH_PARTS]({ dispatch, state }) {
    for (let job of Object.values(state[state.estimorder].jobs)) {
      if (job.details.parts) {
        for (const [index, part] of job.details.parts.entries()) {
          await dispatch(_actions.SEARCH_PARTS, {
            branchId: state[state.estimorder].branchId,
            search: part.partNumber
          });
          const updatedPart = Cloner.shallowClone(state.partList[0]);
          if (updatedPart) {
            updatedPart.quantityRequested = part.quantityRequested;
            updatedPart.description = part.description;
            const jobKey = JSON.stringify({ jobId: job.jobId, operationId: job.operationId });
            await dispatch(_actions.SELECT_PART, {
              jobKey,
              partIndex: index,
              part: updatedPart
            });
          } else {
            // TODO handle if part is not found
          }
        }
      }
    }
  },

  [_actions.UPDATE_QTY_ON_HAND]({ commit, state }, params) {
    for (let job of Object.values(state[state.estimorder].jobs)) {
      job.details.parts.forEach((part, partIndex) => {
        let updatedPart = params.find(p => p.partNumber === part.partNumber);
        if (updatedPart) {
          const jobKey = JSON.stringify({ jobId: job.jobId, operationId: job.operationId });
          commit(_mutations.SET_PART_QTY_ON_HAND, {
            jobKey,
            partIndex,
            quantityOnHand: updatedPart.qtyOnHand
          });
        }
      });
    }
  },

  // ASIDES
  // Workflow
  async [_actions.FETCH_WORKFLOW]({ commit, state }) {
    const params = {
      unitId: state[state.estimorder].unitId,
      serviceOrderId: state[state.estimorder].serviceOrderId
    };
    const workflowResponse = await UnitService.getWorkflow(params);
    const key = 'workflow';
    const value = workflowResponse.data;
    commit(_mutations.SET_PROP, { key, value });
    commit(_mutations.SET_OLD_ESTIMORDER_PROP, { estimorder: state.estimorder, key, value });
  },
  async [_actions.ASSIGN_EMPLOYEE_TO_SERVICE_ORDER]({ commit, state }, workflow) {
    const params = {
      unitId: state[state.estimorder].unitId,
      serviceOrderId: state[state.estimorder].serviceOrderId,
      workflow
    };
    const workflowResponse = await UnitService.assignEmployeeToServiceOrder(params);
    const key = 'workflow';
    const value = workflowResponse.data;
    commit(_mutations.SET_PROP, { key, value });
    commit(_mutations.SET_OLD_ESTIMORDER_PROP, { estimorder: state.estimorder, key, value });
  },

  // Request Approval
  async [_actions.SEND_APPROVAL_REQUEST]({ commit, state, dispatch, getters }, approvalRequest) {
    const params = {
      unitId: state[state.estimorder].unitId,
      serviceOrderId: state[state.estimorder].serviceOrderId,
      approvalRequest
    };
    await UnitService.requestApproval(params);
    dispatch(_actions.FETCH_SERVICE_ORDER, params);
    const estimateId = getters[_getters.GET_ESTIMATE_ID];
    if (estimateId) {
      dispatch(_actions.FETCH_LINKED_SERVICE_ORDER, { ...params, estimateId });
    }
    const key = 'requestApproval';
    const value = {};
    commit(_mutations.SET_PROP, { key, value });
    commit(_mutations.SET_OLD_ESTIMORDER_PROP, { estimorder: state.estimorder, key, value });
  },

  // Unit Ready
  async [_actions.SEND_UNIT_READY]({ commit, state, dispatch }, unitReady) {
    const params = {
      unitId: state[state.estimorder].unitId,
      serviceOrderId: state[state.estimorder].serviceOrderId,
      unitReady
    };
    await UnitService.sendUnitReady(params);
    dispatch(_actions.FETCH_SERVICE_ORDER, params);
    const key = 'unitReady';
    const value = {
      recipients: []
    };
    commit(_mutations.SET_PROP, { key, value });
    commit(_mutations.SET_OLD_ESTIMORDER_PROP, { estimorder: state.estimorder, key, value });
  },

  // Repair Contact
  async [_actions.FETCH_REPAIR_CONTACT]({ commit, state }) {
    const repairContact = await RepairContactService.getRepairContact(
      state[state.estimorder].unitId,
      state[state.estimorder].serviceOrderId
    );
    const key = 'repairContact';
    const value = repairContact.data;
    commit(_mutations.SET_PROP, { key, value });
    commit(_mutations.SET_OLD_ESTIMORDER_PROP, { estimorder: state.estimorder, key, value });
  },
  async [_actions.UPDATE_REPAIR_CONTACT]({ commit, state }) {
    const params = {
      unitId: state[state.estimorder].unitId,
      serviceOrderId: state[state.estimorder].serviceOrderId,
      repairContact: state[state.estimorder].repairContact
    };
    const repairContactResponse = await RepairContactService.updateRepairContact(
      params.unitId,
      params.serviceOrderId,
      params.repairContact
    );
    const key = 'repairContact';
    const value = repairContactResponse.data;
    commit(_mutations.SET_PROP, { key, value });
    commit(_mutations.SET_OLD_ESTIMORDER_PROP, { estimorder: state.estimorder, key, value });
  },

  async [_actions.FETCH_LINKED_SERVICE_ORDER]({ dispatch }, params) {
    let serviceOrderResult;
    serviceOrderResult = await UnitService.getLinkedServiceOrder(
      params.unitId,
      params.serviceOrderId,
      params.estimateId
    );
    const serviceOrder = serviceOrderResult.data;

    await dispatch(_actions.SET_SERVICE_ORDER, {
      serviceOrder,
      _showDetails: !serviceOrder.readonly
    });
  },
  [_actions.SWITCH_STAGE]({ commit, state, getters }, params) {
    const newStage = params.newStage;
    const that = params.that;
    const allVs = [];
    if (that.$v) allVs.push(that.$v);
    if (params.otherVs) allVs.push(...params.otherVs);
    if (allVs.length > 0) {
      allVs.forEach(v => v.$touch());
      if (allVs.some(v => v.$anyError)) {
        const estimorderDisplay = getters[_getters.GET_ESTIMORDER_DISPLAY_TEXT];
        const errorMessage = `Error within the ${estimorderDisplay}. See indicated fields below.`;
        ErrorService.createErrorToast(that, errorMessage);
        return false;
      }
    }

    commit(_mutations.SET_FORM_STAGE, newStage);
    if (
      state.formStage !== FORM_STAGES.CREATING_SO &&
      state.formStage !== FORM_STAGES.ADDING_JOBS &&
      state.formStage !== FORM_STAGES.SAVING_SO
    ) {
      that.$v?.$reset();
    }
    return true;
  },
  async [_actions.FETCH_CUSTOMER_ALERTS]({ commit }, customerId) {
    const response = await CustomerService.getCustomerAlerts(customerId);
    var customerAlerts = response.data;
    var customerAlert = customerAlerts[0] || {};
    commit(_mutations.SET_CUSTOMER_ALERT, customerAlert);
    commit(_mutations.SET_CUSTOMER_ALERTS, response.data);
  },
  async [_actions.FETCH_BILL_TO_CUSTOMER_ALERTS]({ commit }, billToCustomerId) {
    const response = await CustomerService.getCustomerAlerts(billToCustomerId);
    var billToCustomerAlerts = response.data;
    var billToCustomerAlert = billToCustomerAlerts[0] || {};
    commit(_mutations.SET_BILL_TO_CUSTOMER_ALERT, billToCustomerAlert);
    commit(_mutations.SET_BILL_TO_CUSTOMER_ALERTS, response.data);
  },
  async [_actions.FETCH_APPOINTMENT_UNIT_SERVICE_ORDERS]({ commit }, appointmentId) {
    const response = await ServiceOrderService.getAppointmentUnitServiceOrders(appointmentId);
    commit(_mutations.SET_APPOINTMENT_UNIT_SERVICE_ORDERS, response.data);
    return response.data;
  },
  async [_actions.SEND_PAYMENT_REQUEST]({ dispatch }, params) {
    const unitId = params.unitId;
    const serviceOrderId = params.serviceOrderId;
    const ePayRequest = {
      ePayEmail: params.ePayEmail,
      requestedAmount: params.requestedAmount,
      branchId: params.branchId
    };
    const serviceOrderResponse = await UnitService.sendPaymentRequest({
      unitId,
      serviceOrderId,
      ePayRequest
    });
    const serviceOrder = serviceOrderResponse.data;
    await dispatch(_actions.SET_SERVICE_ORDER, {
      serviceOrder
    });
    return serviceOrder;
  },
  async [_actions.SEND_CARD_PAYMENT_REQUEST]({ dispatch }, params) {
    const unitId = params.unitId;
    const serviceOrderId = params.serviceOrderId;
    const ePayRequest = {
      ePayEmail: params.ePayEmail,
      requestedAmount: params.requestedAmount,
      branchId: params.branchId
    };
    const serviceOrderResponse = await UnitService.sendCardPaymentRequest({
      unitId,
      serviceOrderId,
      ePayRequest
    });
    const serviceOrder = serviceOrderResponse.data;
    await dispatch(_actions.SET_SERVICE_ORDER, {
      serviceOrder
    });
    return serviceOrder;
  },
  async [_actions.VOID_PAYMENT_REQUEST]({ dispatch }, params) {
    const unitId = params.unitId;
    const serviceOrderId = params.serviceOrderId;
    const ePayRequest = params.voidRequest;
    const serviceOrderResponse = await UnitService.voidPaymentRequest({
      unitId,
      serviceOrderId,
      ePayRequest
    });
    const serviceOrder = serviceOrderResponse.data;
    await dispatch(_actions.SET_SERVICE_ORDER, {
      serviceOrder
    });
    return serviceOrder;
  },
  async [_actions.REFUND_PAYMENT_REQUEST]({ dispatch }, params) {
    const unitId = params.unitId;
    const serviceOrderId = params.serviceOrderId;
    const ePayRequest = {
      ePayEmail: params.ePayEmail,
      requestedAmount: params.requestedAmount,
      branchId: params.branchId
    };
    const serviceOrderResponse = await UnitService.refundPaymentRequest({
      unitId,
      serviceOrderId,
      ePayRequest
    });
    const serviceOrder = serviceOrderResponse.data;
    await dispatch(_actions.SET_SERVICE_ORDER, {
      serviceOrder
    });
    return serviceOrder;
  }
};
